import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "aphrodite";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";

import RouteNames from "../../constants/routesNames";
import styles from "./styles";

const { Sider } = Layout;

const SideBarComponent = props => {
  const [collapsed, setCollapsed] = useState(0);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const { location } = props;
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme="dark"
      width={200}
      style={{
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <div className={css(styles.logo)}>
        <Link to="/">
          <h1 className={css(styles.brandName)}>
            GO<span className={css(styles.subText)}>PLAY</span>
          </h1>
        </Link>
      </div>
      <Menu
        //defaultSelectedKeys={['dashboard']}
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[RouteNames.Home]}
        selectedKeys={[
          location.pathname === "/" ? RouteNames.Home : location.pathname
        ]}
      >
        <Menu.Item key={RouteNames.Home}>
          <NavLink to={RouteNames.Home}>
            <Icon type="dashboard" title="Dashboard" />
            <span> Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={RouteNames.Games}>
          <NavLink to={RouteNames.Games}>
            <Icon type="play-circle" title="Games" />
            <span> Games</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={RouteNames.UserGames}>
          <NavLink to={RouteNames.UserGames}>
            <Icon type="user" title="User Plays" />
            <span> User Plays</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

SideBarComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
};

export const SideBar = withRouter(SideBarComponent);
