import { put, takeLatest } from "redux-saga/effects";
import {
  GAMES_LOAD,
  GAMES_LIST,
  GAMES_DELETE_LOAD,
  GAMES_FAIL,
  GAMES_DELETE,
  GAMES_SHOW,
  GAMES_SHOW_LOAD,
  GAMES_UPDATE_LOAD,
  GAMES_UPDATE,
  AUTH_LOGOUT,
  GAMES_ADD_LOAD,
  GAMES_ACTIVE_LOAD,
  GAMES_ACTIVE,
  GAMES_USER_LOAD,
  GAMES_USER,
  USER_GAMES_LOADER,
  GAMES_DASHBOARD_LOAD,
  GAMES_DASHBOARD_LOADER,
  GAMES_DASHBOARD,
  GAMES_LOADER
} from "../constants/actionTypes";
import {
  deleteGameService,
  getGameService,
  showGameService,
  updateGameService,
  configureGameService,
  activeGameService,
  userGameService,
  getGameDashboardService
} from "../services/common";

function* getGames(data) {
  try {
    yield put({ type: GAMES_LOADER, data: true });
    const response = yield getGameService();
    // set login expiration
    if (response) {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({ type: GAMES_LIST, data: response.data });
    } else {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error);
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";

    yield put({ type: GAMES_LOADER, data: false });
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* showGame(data) {
  try {
    yield put({ type: GAMES_SHOW, data: '' });
    yield put({ type: GAMES_LOADER, data: true });
    const response = yield showGameService(data.payload.id);
    // set login expiration
    if (response) {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({ type: GAMES_SHOW, data: response.data });
    } else {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
    }
  } catch (error) {
    yield put({ type: GAMES_LOADER, data: false });
    if (error && error.response && error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: "Network Error!" });
    }
  }
}

function* createGame(data) {
  try {
    const response = yield configureGameService(data.payload.requestData);
    // set login expiration
    if (response) {
      yield put({ type: GAMES_UPDATE, data: data.payload.requestData });
      data.payload.onSuccess(response.data);
    } else {
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error);
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* updateGame(data) {
  try {
    yield put({ type: GAMES_LOADER, data: true });
    const response = yield updateGameService(data.payload.requestData);
    // set login expiration
    if (response) {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({ type: GAMES_UPDATE, data: data.payload.requestData });
      data.payload.onSuccess(response.data);
    } else {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error);
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";
    data.payload.onError(errorMessage);
    yield put({ type: GAMES_LOADER, data: false });
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* deleteGame(data) {
  try {
    const response = yield deleteGameService(data.payload.requestData);
    // set login expiration
    if (response) {
      yield put({ type: GAMES_DELETE, data: { id: data.payload.requestData } });
      data.payload.onSuccess(response.data);
    } else {
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error || "Internal server error.");
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* activeGame(data) {
  try {
    yield put({ type: GAMES_LOADER, data: true });
    const response = yield activeGameService(data.payload.requestData);
    if (response) {
      yield put({ type: GAMES_LOADER, data: false });
      data.payload.onSuccess(response.data);
    } else {
      yield put({ type: GAMES_LOADER, data: false });
      yield put({ type: GAMES_ACTIVE });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error || "Internal server error.");
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";
    yield put({ type: GAMES_LOADER, data: false });
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* userGame(data) {
  try {
    yield put({ type: USER_GAMES_LOADER, data: true });
    const response = yield userGameService(data.payload.requestData);
    // set login expiration
    if (response) {
      yield put({ type: USER_GAMES_LOADER, data: false });
      yield put({ type: GAMES_USER, data: response.data });
      data.payload.onSuccess(response.data);
    } else {
      yield put({ type: USER_GAMES_LOADER, data: false });
      yield put({ type: GAMES_USER, data: [] });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error || "Internal server error.");
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";
    yield put({ type: GAMES_USER, data: [] });
    yield put({ type: USER_GAMES_LOADER, data: false });
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      data.payload.onError(errorMessage);
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* getGameDashboard(data) {
  try {
    yield put({ type: GAMES_DASHBOARD_LOADER, data: true });
    const response = yield getGameDashboardService();
    // set login expiration
    if (response) {
      yield put({ type: GAMES_DASHBOARD, data: {} });
      yield put({ type: GAMES_DASHBOARD_LOADER, data: false });
      yield put({ type: GAMES_DASHBOARD, data: response.data });
    } else {
      yield put({ type: GAMES_DASHBOARD_LOADER, data: false });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error);
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";

    yield put({ type: GAMES_DASHBOARD_LOADER, data: false });
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}

function* getGamesWatcher() {
  yield takeLatest(GAMES_LOAD, getGames);
  yield takeLatest(GAMES_DELETE_LOAD, deleteGame);
  yield takeLatest(GAMES_SHOW_LOAD, showGame);
  yield takeLatest(GAMES_UPDATE_LOAD, updateGame);
  yield takeLatest(GAMES_ADD_LOAD, createGame);
  yield takeLatest(GAMES_ACTIVE_LOAD, activeGame);
  yield takeLatest(GAMES_USER_LOAD, userGame);
  yield takeLatest(GAMES_DASHBOARD_LOAD, getGameDashboard)
}

export { getGamesWatcher };
