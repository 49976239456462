/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Axios integration with global error handling for all requests and responce
 */

/* eslint-disable func-names */
import axios from "axios";

// Set config defaults when creating the instance
const instance = axios.create({
  // Use env variable REACT_APP_DEFAULT_AXIOS_URL if you want to override
  // the default URL. Default points to staging backend:
  baseURL: process.env.REACT_APP_DEFAULT_AXIOS_URL || "https://go-play.go-shop.com.my"
});

// Alter defaults after instance has been created
instance.defaults.headers.common.Authorization =
  localStorage.getItem("token") || "";
// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
