/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Add all utility function here
 */
import _ from "loadsh";
import { notification } from "antd";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const openNotificationWithIcon = ({ type, title, message }) => {
  notification[type]({
    message: title,
    description: message
  });
};

export const setUserSession = (request, response) => {
  // const expiresIn = _.get(response, 'data.expiresIn', 60 * 60);
  const expiresIn = _.get(response, "data.expiry_time", 60 * 60);
  const expirationDate = new Date(expiresIn * 1000);
  const email = _.get(response, "data.email", null);
  const auth_token = _.get(response, "data.auth_token", null);

  localStorage.setItem("token", auth_token);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("userId", email);

  openNotificationWithIcon({
    type: "success",
    title: `Welcome! You have successfully logged in.`
  });
  return true;
};

export const allFieldsNotEmpty = data => {
  return data.filter(item => {
    const itemObject = Object.keys(item);
    let isFieldCheck = false;
    itemObject &&
      itemObject.every(e => {
        if (item[e] !== "" && item[e] !== null && item[e] !== undefined) {
          isFieldCheck = true;
          return true;
        }
        isFieldCheck = false;
        return false;
      });
    if (isFieldCheck) return item;
    // return false;
  });
};

export const lastSyncDateAndTime = () => {
  const today = new Date();
  const dd = today.getDate();
  let mm = today.getMonth() + 1;
  let hh = today.getHours();
  let tm = today.getMinutes();
  let ss = today.getSeconds();
  const yyyy = today.getFullYear();
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (tm < 10) {
    tm = `0${tm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }
  return `${dd}-${mm}-${yyyy} [${hh}:${tm}:${ss}]`;
}

export const currentdate = () => {
  const today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};
