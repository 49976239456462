import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import asyncComponent from "../hoc/asyncComponent";
import Layout from "../hoc/Layout";
import Logout from "../containers/Logout";
import RouteNames from "../constants/routesNames";
import { logout, authSuccess } from "../actions";
import { history } from "../store";

// load component asynchronously, helps to avoid unnecessary loading of containers
const asyncHome = asyncComponent(() => {
  return import("../containers/Home");
});

const Games = asyncComponent(() => {
  return import("../containers/Games");
});

const UserGames = asyncComponent(() => {
  return import("../containers/UserGames");
});

const EditGame = asyncComponent(() => {
  return import("../containers/Games/editGame");
});

const ViewGame = asyncComponent(() => {
  return import("../containers/Games/viewGame");
});

const ConfigureGame = asyncComponent(() => {
  return import("../containers/Games/createGame");
});

const SnatchEditGame = asyncComponent(() => {
  return import("../containers/SnatchGame/editGame");
});

const SnatchViewGame = asyncComponent(() => {
  return import("../containers/SnatchGame/viewGame");
})

const asyncAuth = asyncComponent(() => {
  return import("../containers/Auth");
});

const Routes = props => {
  const [state, setState] = useState({
    isLoading: true
  });

  const checkAuthTimeout = (expirationTime) => {
    setTimeout(() => {
      logout();
    }, expirationTime * 1000);
  };

  useEffect(() => {
    // check for login expiration
    // eslint-disable-next-line no-shadow
    const { logout, authSuccess } = props;
    const token = localStorage.getItem("token");
    if (!token) {
      logout();
      setState({ isLoading: false });
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        logout();
        setState({ isLoading: false });
      } else {
        const data = {
          email: localStorage.getItem("userId"),
          expirationDate: localStorage.getItem("expirationDate"),
          auth_token: localStorage.getItem("token"),
        };
        authSuccess(token, data);
        setState({ isLoading: false });
        checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        );
      }
    }
  }, []);

  const { isAuthenticated } = props;

  let routes = (
    <Switch>
      <Route path={RouteNames.SignIn} exact component={asyncAuth} />
      <Redirect to={RouteNames.SignIn} />
    </Switch>
  );

  if (isAuthenticated) {
    routes = (
      <Layout>
        <Switch>
          <Route path={RouteNames.Home} exact component={asyncHome} />
          <Route path={RouteNames.Games} exact component={Games} />
          <Route path={RouteNames.UserGames} exact component={UserGames} />
          <Route
            path={`${RouteNames.GameEdit}/:id`}
            exact
            component={EditGame}
          />
          <Route
            path={`${RouteNames.SnatchGameEdit}/:id`}
            exact
            component={SnatchEditGame}
          />
          <Route
            path={`${RouteNames.SnatchGameView}/:id`}
            exact
            component={SnatchViewGame}
          />
          <Route
            path={`${RouteNames.GameView}/:id`}
            exact
            component={ViewGame}
          />
          <Route
            path={`${RouteNames.GameConfigure}`}
            exact
            component={ConfigureGame}
          />
          <Route path={RouteNames.Logout} exact component={Logout} />
          <Redirect to={RouteNames.Home} />
        </Switch>
      </Layout>
    );
  }

  // Wait for checking isAuthenticated
  if (state.isLoading) {
    return false;
  }
  return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
};

Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  authSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps, {
  logout,
  authSuccess,
})(Routes);
