/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Combine all sagas files here
 */
import { all } from "redux-saga/effects";
import { postAuthWatcher } from "./auth";
import { getGamesWatcher } from "./games";
import { getLeaderBoardWatcher } from "./leaderBoards";

export default function* rootSaga() {
  yield all([postAuthWatcher(), getGamesWatcher(), getLeaderBoardWatcher()]);
}
