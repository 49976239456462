import api from "./api";

export const getGameService = () => api.get(`/allgames`);

export const configureGameService = data => {
  const res = api.post(`/game/configure`, data);
  return res;
};

export const showGameService = id => api.get(`/game-detail/${id}`);

export const updateGameService = data => {
  const res = api.post(`/game-detail/${data.gid}`, data);
  return res;
};

export const activeGameService = data => {
  const res = api.post(
    `/games/${data.gid}/${(data.value && "activate") || "deactived"}`
  );
  return res;
};

export const deleteGameService = id => api.delete(`/games/${id}`);

export const userGameService = data => {
  const res = api.post(`/getUserGames`, data);
  return res;
};

export const getGameDashboardService = () => api.get(`/getGameStatus`);

export const leaderBoardService = data => {
  const res = api.get(`/leaderboards/${data.gid}`);
  return res;
};
