import React from "react";
import { connect } from "react-redux";
import _ from "loadsh";
import { css } from "aphrodite";
import { NavLink, withRouter } from "react-router-dom";
import { Layout } from "antd";
import { SideBar } from "../../components";
import commonStyles from "../../styles/common";
import styles from "./styles";
import routesNames from "../../constants/routesNames";

const { Header, Content } = Layout;

const Layout2 = props => {
  const { children, isAuthenticated } = props;
  // const { pathname } = location;
  // let menu = pathname !== RouteNames.SignIn ? 'Login' : 'Home';
  // let menuLink = pathname !== RouteNames.SignIn ? RouteNames.SignIn : RouteNames.Home;
  if (isAuthenticated) {
    // menu = pathname !== RouteNames.SignIn ? 'Logout' : 'Home';
    // menuLink = pathname !== RouteNames.SignIn ? RouteNames.Logout : RouteNames.Home;
  }
  return (
    <>
      <Layout style={{ minHeight: "100%" }}>
        <SideBar />

        <Layout>
          <Header className={css(styles.siteHeader)}>
            {isAuthenticated && (
              <div className={css(styles.rightContent)}>
                <NavLink
                  to={routesNames.Logout}
                  className={css(styles.link, commonStyles.linkColor)}
                >
                  Logout
                </NavLink>
              </div>
            )}
          </Header>
          <Content className={css(styles.bodyContainer)}>
            <div className={css(styles.bodyContent)}>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    planetData: _.get(state, "common.planetData", []),
    loading: _.get(state, "common.loading", false)
  };
};

export default withRouter(connect(mapStateToProps, null)(Layout2));
