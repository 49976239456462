import React, { useEffect, useState } from "react";

const asyncComponent = importComponent => {
  const Comp = props => {
    const [state, setState] = useState({
      component: null
    });

    useEffect(() => {
      importComponent().then(cmp => {
        setState({ component: cmp.default });
      });
    }, []);

    const { component } = state;
    const C = component;

    return C ? <C {...props} /> : null;
  };
  return Comp;
};

export default asyncComponent;
