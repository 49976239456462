import {
  LEADER_BOARD,
  LEADER_BOARD_LOADER,
  CLEAR_LEADER_BOARD,
} from "../constants/actionTypes";

const initialState = {
  leaderBoard: [],
  leaderBoardLoader: false,
  persistentList: [],
};

const leaderBoard = (state = initialState, action) => {
  switch (action.type) {
    case LEADER_BOARD:
      return {
        ...state,
        leaderBoard: action.data,
      };
    case LEADER_BOARD_LOADER:
      return {
        ...state,
        leaderBoardLoader: action.data,
      };
    case CLEAR_LEADER_BOARD:
      return {
        ...state,
        leaderBoard: [],
      };
    default:
      return state;
  }
};

export default leaderBoard;
