/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Add auth related action here
 */
import {
  AUTH_START,
  AUTH_SUCCESS,
  SET_AUTH_REDIRECT_PATH,
  AUTH_LOGOUT
} from "../constants/actionTypes";

export const postAuth = payload => {
  return {
    type: AUTH_START,
    payload
  };
};

export const authSuccess = (token, data) => {
  return {
    type: AUTH_SUCCESS,
    idToken: token,
    userId: data.email,
    data
  };
};

export const logout = () => {
  return {
    type: AUTH_LOGOUT
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: SET_AUTH_REDIRECT_PATH,
    path
  };
};
