/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Combine all reducer files here
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth";
import commonReducer from "./common";
import gameReducer from "./games";
import leaderBoardReducer from "./leaderBoard";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    common: commonReducer,
    games: gameReducer,
    leaderBoard: leaderBoardReducer,
  });

export default createRootReducer;
