import { put, takeLatest } from "redux-saga/effects";
import {
    LEADER_BOARD,
    LEADER_BOARD_LOAD,
    LEADER_BOARD_LOADER,
    AUTH_LOGOUT,
    GAMES_FAIL
} from "../constants/actionTypes";
import {
  leaderBoardService,
} from "../services/common";

function* getLeaderBoard(data) {
  try {
    yield put({ type: LEADER_BOARD_LOADER, data: true });
    const response = yield leaderBoardService(data.payload.requestData);
    // set leaderboard expiration
    if (response) {
      yield put({ type: LEADER_BOARD_LOADER, data: false });
      yield put({ type: LEADER_BOARD, data: response.data });
    } else {
      yield put({ type: LEADER_BOARD_LOADER, data: false });
      yield put({
        type: GAMES_FAIL,
        error: response.error || "Internal server error."
      });
      data.payload.onError(response.error);
    }
  } catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "Network Error!";

    yield put({ type: LEADER_BOARD_LOADER, data: false });
    data.payload.onError(errorMessage);
    if (error.response.status === 401) {
      yield put({ type: AUTH_LOGOUT });
    } else {
      yield put({ type: GAMES_FAIL, error: errorMessage });
    }
  }
}


function* getLeaderBoardWatcher() {
  yield takeLatest(LEADER_BOARD_LOAD, getLeaderBoard);
}

export { getLeaderBoardWatcher };
