/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Define all app routes here
 */
export default {
  // eslint-disable-next-line no-script-url
  Dummy: "#",
  SignIn: "/sign-in",
  Logout: "/logout",
  Home: "/home",
  Games: "/games",
  GameView: "/games/view",
  GameEdit: "/games/edit",
  GameConfigure: "/games/create",
  UserGames: "/usergames",
  SnatchGameEdit: "/Snatchgames/edit",
  SnatchGameView: "/Snatchgames/view",
};
