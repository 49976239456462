import { StyleSheet } from "aphrodite";
import colors from "./colors";

export default StyleSheet.create({
  linkColor: {
    color: colors.brandPrimary,
    ":hover": {
      color: colors.pink
    }
  },
  active: {
    color: colors.pink
  },
  linkDisabled: {
    color: colors.brandSecondary,
    cursor: "not-allowed"
  },
  error: {
    color: colors.brandError
  }
});
