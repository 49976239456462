import {
  GAMES_LOAD,
  GAMES_LIST,
  GAMES_UPDATE,
  GAMES_DELETE,
  GAMES_FAIL,
  GAMES_SHOW,
  GAMES_ADD,
  GAMES_ACTIVE,
  GAMES_SEARCH,
  GAMES_USER,
  USER_GAMES_LOADER,
  GAMES_DASHBOARD_LOADER,
  GAMES_DASHBOARD,
  GAMES_LOADER
} from "../constants/actionTypes";
import { lastSyncDateAndTime } from "../utils";

const initialState = {
  list: [],
  currentGame: {},
  error: null,
  loading: false,
  userGame: [],
  userGameLoader: false,
  gameDashboardLoader: false,
  gameDashboard: {},
  lastSync: "",
  persistentList: []
};

const addGames = (state, action) => {
  const gameList = [...state.list];
  const newGame = {
    gid: action.data.gid,
    title: action.data.game_name,
    created_at: "2020-11-21T18:30:00.000Z",
    start_time: "2020-11-21T18:30:00.000Z",
    end_time: "2020-11-21T18:30:00.000Z",
    start_date: "2020-11-21T18:30:00.000Z",
    end_date: "2020-11-21T18:30:00.000Z",
    is_active: "Yes"
  };
  gameList.concat(newGame);
  return {
    ...state,
    list: gameList
  };
};

const searchGames = (state, action) => {
  const gameList = [...state.persistentList];
  const filteredGameListByQuery = gameList.filter(item => {
    if (
      (!action.payload.query.trim() ||
        item?.title?.indexOf(action.payload.query.trim()) > -1 ||
        item?.gid?.indexOf(action.payload.query.trim()) > -1) &&
      (!action.payload.selectedGameType ||
        item?.type === action.payload.selectedGameType)
    ) {
      return true;
    }
    return false;
  });
  return {
    ...state,
    list: filteredGameListByQuery
  };
};

const updateGames = (state, action) => {
  let gameList = [...state.list];
  gameList = gameList.map(item => {
    if (item.gid === action.data.gid) {
      return action.data;
    }
    return item;
  });
  return {
    ...state,
    list: gameList
  };
};

const deleteGames = (state, action) => {
  let gameList = [...state.list];
  gameList = gameList.filter(item => item.id !== action.data.id);
  return {
    ...state,
    list: gameList
  };
};

const games = (state = initialState, action) => {
  switch (action.type) {
    case GAMES_LOAD:
      return {
        ...state
      };
    case GAMES_LIST:
      return {
        ...state,
        list: action.data,
        persistentList: action.data
      };
    case GAMES_SHOW:
      return {
        ...state,
        currentGame: action.data
      };
    case GAMES_ADD:
      return addGames(state, action);
    case GAMES_SEARCH:
      return searchGames(state, action);
    case GAMES_UPDATE:
      return updateGames(state, action);
    case GAMES_DELETE:
      return deleteGames(state, action);
    case GAMES_ACTIVE:
      return {
        ...state,
        list: [...state.list]
      };
    case GAMES_USER:
      return {
        ...state,
        userGame: action.data
      };
    case USER_GAMES_LOADER:
      return {
        ...state,
        userGameLoader: action.data
      };
    case GAMES_DASHBOARD_LOADER:
      return {
        ...state,
        gameDashboardLoader: action.data
      };
    case GAMES_DASHBOARD:
      return {
        ...state,
        gameDashboard: action.data,
        lastSync: lastSyncDateAndTime()
      };
    case GAMES_LOADER:
      return {
        ...state,
        loading: action.data
      };
    case GAMES_FAIL:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default games;
