import React from "react";
import PropTypes from "prop-types";
import { Form, Icon, Input, Button } from "antd";
import { css } from "aphrodite";
import commonStyles from "../../styles/common";
import styles from "./styles";

const LoginForm = props => {
  const handleSubmit = e => {
    e.preventDefault();
    const { form, handleSubmit } = props;
    form.validateFields((err, values) => {
      if (!err) {
        handleSubmit(values);
      }
    });
  };

  const { form, loading, error } = props;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={handleSubmit} className={css(styles.loginForm)}>
      <div className={css(styles.errorWrap)}>
        {error ? (
          <p className={css(styles.error, commonStyles.error)}>{error}</p>
        ) : (
          <span />
        )}
      </div>
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [{ required: true, message: "Please input your email!" }]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Username"
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [{ required: true, message: "Please input your Password!" }]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="danger"
          htmlType="submit"
          className={css(styles.loginFormButton)}
          loading={loading}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}

LoginForm.defaultProps = {
  form: () => undefined,
  getFieldDecorator: () => undefined,
  validateFields: () => undefined,
  error: null
};

LoginForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]),
  getFieldDecorator: PropTypes.func,
  validateFields: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export const NormalLoginForm = Form.create({ name: "normal_login" })(LoginForm);
