import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import { Input } from "antd";

export const NumberField = forwardRef((props, ref) => {
  const triggerChange = changedValue => {
    const { onChange } = props;
    if (onChange) {
      onChange(changedValue);
    }
  };
  const handleNumberChange = e => {
    const number = parseInt(e.target.value || 0, 10);
    if (Number.isNaN(number)) {
      return;
    }
    triggerChange(number);
  };

  const { value, name, placeholder } = props;

  return (
    <Input
      ref={ref}
      type="text"
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={handleNumberChange}
    />
  );
});

NumberField.defaultProps = {
  placeholder: "",
  name: "",
  onChange: null,
};

NumberField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};
