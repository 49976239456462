import api from "./api";

export const postAuthService = async data => {
  const response = await api.post(`/admin`, data);
  // Set Token in request header
  if (response.status >= 200 && response.status < 400) {
    api.defaults.headers.common.Authorization = response.data.auth_token;
  }
  return response;
};
