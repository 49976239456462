import { StyleSheet } from "aphrodite";
import colors from "../../styles/colors";

export default StyleSheet.create({
  header: {
    backgroundColor: colors.white,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    boxShadow: "0 0.125rem 0.25rem rgba(51, 51, 51, 0.075)",
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    zIndex: "100"
  },
  headerSmall: {
    "@media (max-width: 600px)": {
      paddingLeft: "10px",
      paddingRight: "10px"
    }
  },
  leftContent: {
    width: "30%",
    display: "flex",
    alignItems: "center"
  },
  leftContentSmall: {
    "@media (max-width: 600px)": {
      display: "none"
    }
  },
  middleContent: {
    height: "70px",
    width: "40%",
    padding: "20px",
    display: "flex",
    alignItems: "center"
  },
  middleContentSmall: {
    "@media (max-width: 600px)": {
      width: "70%"
    }
  },
  rightContent: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  link: {
    verticalAlign: "middle",
    float: "right",
    textDecoration: "none",
    padding: "20px"
  },
  searchBox: {
    width: "100%"
  },

  bodyContainer: {
    margin: "16px"
  },
  bodyContent: {
    background: "#fff",
    padding: 24,
    minHeight: 360
  },
  siteHeader: {
    display: "flex",
    background: "#fff",
    padding: 0,
    justifyContent: "flex-end"
  },
  siteLayoutBackground: {
    background: "#fff"
  }
});
