/**
 * Auther: Imaginato
 * Date: 01-01-2021
 * Desc: Export all constants for actions
 */

// Auth
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

// Games
export const GAMES_LOAD = "GAMES_LOAD";
export const GAMES_LIST = "GAMES_LIST";
export const GAMES_SEARCH = "GAMES_SEARCH";

export const GAMES_ADD_LOAD = "GAMES_ADD_LOAD";
export const GAMES_ADD = "GAMES_ADD";

export const GAMES_SHOW_LOAD = "GAMES_SHOW_LOAD";
export const GAMES_SHOW = "GAMES_SHOW";

export const GAMES_UPDATE_LOAD = "GAMES_UPDATE_LOAD";
export const GAMES_UPDATE = "GAMES_UPDATE";

export const GAMES_DELETE_LOAD = "GAMES_DELETE_LOAD";
export const GAMES_DELETE = "GAMES_DELETE";

export const GAMES_FAIL = "GAMES_FAIL";

export const GAMES_ACTIVE_LOAD = "GAMES_ACTIVE_LOAD";
export const GAMES_ACTIVE = "GAMES_ACTIVE";

export const GAMES_USER_LOAD = "GAMES_USER_LOAD";
export const GAMES_USER = "GAMES_USER";
export const USER_GAMES_LOADER = "USER_GAMES_LOADER";

export const GAMES_DASHBOARD_LOAD = "GAMES_DASHBOARD_LOAD";
export const GAMES_DASHBOARD = "GAMES_DASHBOARD";
export const GAMES_DASHBOARD_LOADER = "GAMES_DASHBOARD_LOADER";

export const GAMES_LOADER = "GAMES_LOADER";

export const LEADER_BOARD = "LEADER_BOARD";
export const LEADER_BOARD_LOAD = "LEADER_BOARD_LOAD";
export const LEADER_BOARD_LOADER = "LEADER_BOARD_LOADER";
export const CLEAR_LEADER_BOARD = "CLEAR_LEADER_BOARD";
