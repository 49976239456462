import { StyleSheet } from "aphrodite";
import colors from "../../styles/colors";

export default StyleSheet.create({
  container: {
    height: "100%",
    width: 256,
    minWidth: "256px",
    paddingTop: "70px"
  },
  brandName: {
    fontWeight: "bold",
    color: colors.pink,
    margin: "0",
    lineHeight: "24px",
    fontSize: "28px",
    transition: 'font-size 0.2s'
  },
  subText: {
    color: colors.white
  },
  logo: {
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});
