import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  container: {
    width: "100%"
  },
  search: {
    width: "100%"
  }
});
